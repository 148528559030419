////////////////////////////////////////////// Helper code, do not edit /////////////////////////////////////////
import { allIds, fetchOrderById } from '../api/index';

////////////////////////////////// Your code tasks is below //////////////////////////////////////////////////////

const fetchAllOrders = () => {
  //   1. todo, fetch all ids using the "fetchOrderById" and the given ids, make it work as efficient and clean as possible.

  const ids = allIds;
  const promises = [];
  for (let i = 0; i < allIds.length; i++) {
    promises.push(fetchOrderById(allIds[i]));
  }

  return Promise.all(promises)
    .then((results) => {
      return results;
    })
    .catch((e) => {
      console.log(e);
    });
};

const bucketOrdersByUsers = () => {
  var ordersByUsers = {};
  /**
   * 2. todo, using the function from section 1 you should now bucket the orders by user.
   *  each key in the object (ordersByUsers) represents a userId and each value is an array of the orders of that user.
   */

  return fetchAllOrders().then((items) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (ordersByUsers[item.userId] === undefined)
        ordersByUsers[item.userId] = new Array();

      ordersByUsers[item.userId].push(item);
    }
    return ordersByUsers;
  });
};

const getLast2WeeksOrders = () => {
  /**
   * 3. todo, fetch all Ids and return array with only the last 2 weeks orders. make it work as efficient and clean as possible.
   * - since we are dealing with unix time we will be converting things into milisecounds
   */

  var twoWeeks = 604800000 * 2; // two weeks in miliseconds
  var today = new Date().getTime();
  return fetchAllOrders().then((items) => {
    return items.filter(({ timestamp }) => timestamp >= today - twoWeeks);
  });
};

const bucketOrdersByDate = () => {
  var ordersByDate = {};
  //   4. todo, using the function from section 3 bucket the orders by date.
  // each key in the object (ordersByDate) represents a day and each value is an array of the orders in that date.
  return getLast2WeeksOrders().then((items) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (ordersByDate[item.timestamp] === undefined)
        ordersByDate[item.timestamp] = new Array();
    }
    fetchAllOrders().then((orders) => {
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        if (ordersByDate[order.timestamp] === undefined)
        ordersByDate[order.timestamp]
      }
    });
    return ordersByDate;
    // console.log(items);
  });
};

fetchAllOrders().then(console.log);

bucketOrdersByUsers().then(console.log);

getLast2WeeksOrders().then(console.log);
// .then(console.log);

bucketOrdersByDate().then(console.log);
// .then(console.log);

////////////////////////////////////////
