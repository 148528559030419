<template>
  <v-container class="image-carousle">
    <v-row align="center" justify="space-between">
      <v-btn class="previous" @click="slide(false)" x-large icon>
        <v-icon x-large>mdi-chevron-left</v-icon>
      </v-btn>

      <v-img max-width="60%" :src="images[currSlide]"></v-img>

      <v-btn class="next" @click="slide(true)" x-large icon>
        <v-icon x-large>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
    <p class="stats">{{currSlide + 1}}/{{images.length}}</p>
  </v-container>
</template>

<script>
import { fetchImages } from "../api/index";
import "../submission2/submission_e-commerce";

export default {
  name: "ImageCarousle",
  data: () => ({
    currSlide: 0,
    images: [],
  }),
  mounted() {
    // after the component mounts call this method
    this.setImages();
  },
  methods: {
    setImages() {
      // calls images from api and sets it in components data
      fetchImages().then((response) => {
        this.images = response;
      });
    },
    slide(dir) {
      if (dir) {
        this.currSlide === this.images.length - 1
          ? (this.currSlide = 0)
          : this.currSlide++;
      } else {
        this.currSlide === 0
          ? (this.currSlide = this.images.length - 1)
          : this.currSlide--;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
