export const allIds = [
  '-LsGiUmbxSiH_xOpTtNy',
  '-LsGiUmeYywUelYBdxsj',
  '-LsGiUmf_mQvW_P7p0YT',
  '-LsGiUmf_mQvW_P7p0YU',
  '-LsGiUmf_mQvW_P7p0YV',
  '-LsGiUmf_mQvW_P7p0YW',
  '-LsGiUmf_mQvW_P7p0YX',
  '-LsGiUmf_mQvW_P7p0YY',
  '-LsGiUmf_mQvW_P7p0YZ',
  '-LsGiUmf_mQvW_P7p0Y_',
  '-LsGiUmf_mQvW_P7p0Ya',
  '-LsGiUmf_mQvW_P7p0Yb',
  '-LsGiUmf_mQvW_P7p0Yc',
  '-LsGiUmf_mQvW_P7p0Yd',
  '-LsGiUmg_ZUmcP2eOcy1',
  '-LsGiUmg_ZUmcP2eOcy2',
  '-LsGiUmg_ZUmcP2eOcy3',
  '-LsGiUmg_ZUmcP2eOcy4',
  '-LsGiUmg_ZUmcP2eOcy5',
  '-LsGiUmg_ZUmcP2eOcy6',
  '-LsGiUmg_ZUmcP2eOcy7',
  '-LsGiUmg_ZUmcP2eOcy8',
  '-LsGiUmg_ZUmcP2eOcy9',
  '-LsGiUmg_ZUmcP2eOcyA',
  '-LsGiUmg_ZUmcP2eOcyB',
  '-LsGiUmg_ZUmcP2eOcyC',
  '-LsGiUmg_ZUmcP2eOcyD',
  '-LsGiUmg_ZUmcP2eOcyE',
  '-LsGiUmhd6I8I2MaGozF',
  '-LsGiUmhd6I8I2MaGozG',
  '-LsGiUmhd6I8I2MaGozH',
  '-LsGiUmhd6I8I2MaGozI',
  '-LsGiUmhd6I8I2MaGozJ',
  '-LsGiUmhd6I8I2MaGozK',
  '-LsGiUmhd6I8I2MaGozL',
  '-LsGiUmhd6I8I2MaGozM',
  '-LsGiUmhd6I8I2MaGozN',
  '-LsGiUmhd6I8I2MaGozO',
  '-LsGiUmhd6I8I2MaGozP',
  '-LsGiUmhd6I8I2MaGozQ',
  '-LsGiUmhd6I8I2MaGozR',
  '-LsGiUmhd6I8I2MaGozS',
  '-LsGiUmhd6I8I2MaGozT',
  '-LsGiUmhd6I8I2MaGozU',
  '-LsGiUmisHe4YEtNmNN-',
  '-LsGiUmisHe4YEtNmNN0',
  '-LsGiUmisHe4YEtNmNN1',
  '-LsGiUmisHe4YEtNmNN2',
  '-LsGiUmisHe4YEtNmNN3',
  '-LsGiUmisHe4YEtNmNN4',
  '-LsGiUmisHe4YEtNmNN5',
  '-LsGiUmisHe4YEtNmNN6',
  '-LsGiUmisHe4YEtNmNN7',
  '-LsGiUmisHe4YEtNmNN8',
  '-LsGiUmisHe4YEtNmNN9',
  '-LsGiUmisHe4YEtNmNNA',
  '-LsGiUmisHe4YEtNmNNB',
  '-LsGiUmisHe4YEtNmNNC',
  '-LsGiUmisHe4YEtNmNND',
  '-LsGiUmj50V2v8Q0zEWP',
  '-LsGiUmj50V2v8Q0zEWQ',
  '-LsGiUmj50V2v8Q0zEWR',
  '-LsGiUmj50V2v8Q0zEWS',
  '-LsGiUmj50V2v8Q0zEWT',
  '-LsGiUmj50V2v8Q0zEWU',
  '-LsGiUmj50V2v8Q0zEWV',
  '-LsGiUmj50V2v8Q0zEWW',
  '-LsGiUmj50V2v8Q0zEWX',
  '-LsGiUmj50V2v8Q0zEWY',
  '-LsGiUmj50V2v8Q0zEWZ',
  '-LsGiUmj50V2v8Q0zEW_',
  '-LsGiUmj50V2v8Q0zEWa',
  '-LsGiUmj50V2v8Q0zEWb',
  '-LsGiUmj50V2v8Q0zEWc',
  '-LsGiUmj50V2v8Q0zEWd',
  '-LsGiUmkDUBm9KzpWk4u',
  '-LsGiUmkDUBm9KzpWk4v',
  '-LsGiUmkDUBm9KzpWk4w',
  '-LsGiUmkDUBm9KzpWk4x',
  '-LsGiUmkDUBm9KzpWk4y',
  '-LsGiUmkDUBm9KzpWk4z',
  '-LsGiUmkDUBm9KzpWk5-',
  '-LsGiUmkDUBm9KzpWk50',
  '-LsGiUmkDUBm9KzpWk51',
  '-LsGiUmkDUBm9KzpWk52',
  '-LsGiUmkDUBm9KzpWk53',
  '-LsGiUmkDUBm9KzpWk54',
  '-LsGiUmkDUBm9KzpWk55',
  '-LsGiUmkDUBm9KzpWk56',
  '-LsGiUmkDUBm9KzpWk57',
  '-LsGiUmkDUBm9KzpWk58',
  '-LsGiUmluY33UvNl-5zu',
  '-LsGiUmluY33UvNl-5zv',
  '-LsGiUmluY33UvNl-5zw',
  '-LsGiUmluY33UvNl-5zx',
  '-LsGiUmluY33UvNl-5zy',
  '-LsGiUmluY33UvNl-5zz',
  '-LsGiUmluY33UvNl-6--',
  '-LsGiUmluY33UvNl-6-0',
  '-LsGiUmluY33UvNl-6-1',
];
const usersIds = [
  '70ef59985eca171b2bce81a1',
  '70ef59985eca171b2bce81fe',
  '70ef59985eca171b2bce81ff',
  '70ef59985eca171b2bce8206',
  '70ef59985eca171b2bce824d',
  '70ef59985eca171b2bce825d',
  '70ef599e5eca171b2bce84c7',
  '70ef599e5eca171b2bce84c9',
  '70ef599e5eca171b2bce84d1',
  '70ef599e5eca171b2bce84dd',
];

function setTimeoutPromise(timeout, values) {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(values), timeout);
  });
}

export function fetchOrderById(id) {
  //use it to fetch single order by Id . do not change this function
  return setTimeoutPromise(200, {
    id,
    userId: usersIds[Math.floor(Math.random() * usersIds.length - 0.000001)],
    timestamp:
      Date.now() - Math.round(Math.random() * 60 * 24 * 60 * 60 * 1000),
    title: `Order ${id}`,
  });
}

const imgUrls = [
  'https://firebasestorage.googleapis.com/v0/b/juno-development.appspot.com/o/coding_task%2F1.jpg?alt=media&token=e2f54b7a-17b8-4ff5-8bb1-fcce587ed827',
  'https://firebasestorage.googleapis.com/v0/b/juno-development.appspot.com/o/coding_task%2F2.jpg?alt=media&token=6189cdb9-15b5-43a8-857b-12ba97b629ba',
  'https://firebasestorage.googleapis.com/v0/b/juno-development.appspot.com/o/coding_task%2F3.jpg?alt=media&token=1205cb1e-49c0-45b4-a37e-141ca0f248e2',
  'https://firebasestorage.googleapis.com/v0/b/juno-development.appspot.com/o/coding_task%2F4.jpg?alt=media&token=d0b60c24-c89a-4764-83fc-55ca18a67b19',
  'https://firebasestorage.googleapis.com/v0/b/juno-development.appspot.com/o/coding_task%2F5.jpg?alt=media&token=31a8bf1d-af20-48bc-988e-5769558e1e35',
];
export function fetchImages() {
  return setTimeoutPromise(500, imgUrls);
}
